@font-face {
  font-family: Dosis;
  src: url("/public/fonts/Dosis.ttf");
}

@font-face {
  font-family: 'CooperHewitt-Medium';
  src: url("/public/fonts/CooperHewitt-Medium.otf");
}

@font-face {
  font-family: 'CooperHewitt-Thin';
  src: url("/public/fonts/CooperHewitt-Thin.otf");
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('/public/fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('/public/fonts/Montserrat-Medium.ttf');
}

@font-face {
  font-family: 'Montserrat-Thin';
  src: url('/public/fonts/Montserrat-Thin.ttf');
}

#root {
  margin: 0;
  padding: 0;
}

html {
  background-color: var(--background);
  color: var(--main-text);
  font-family: Dosis, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  color: var(--main-text);
  text-decoration-color: var(--primary);
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
}