:root {
  --background: #202020;
  --main-bg: #333333;
  --main-text: #ffffff;
  --main-hover: #444444;
  --main-active: #555555;
  --main-color: #0B6E4F;
  --main-color-transparent: #0B6E4Fcc;
  /*--main-color: #073B39;
  --main-color: #083B1C;*/
  --main-color-dark: #0D4A1E;
  --primary: #1BA514;
  --selected: #3e574f;
}

.switchPage {}

.margintopthings {
  padding-top: 0px;
}

.paddingfifty {
  padding-inline: 16px;
  width: 50%;
  margin: 0 auto;
}

.mainimg {
  position: relative;
  width: 100%;
  animation-name: dropin;
  animation-duration: 1s;
}

.opacitytext {
  opacity: 0.25;
}

.logoimg {
  position: absolute;
  width: 72px;
  top: 16px;
  right: 0;
}

.meboxpadding {
  padding-inline: 0px;
}

textarea {
  font-family: 'Montserrat-Regular', 'CooperHewitt-Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

input {
  font-family: 'Montserrat-Regular', 'CooperHewitt-Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.mainlayoutpadding {
  padding-inline: 32px;
}

.notselectable {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.App {
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat-Regular', 'CooperHewitt-Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.center {
  margin: 0 auto;
  margin-top: 16px;
}

.endmargin {
  margin-bottom: 16px;
}

h3 {
  font-size: 1.5rem;
}

a {
  color: var(--main-text);
  font-size: 1.25rem;
}

body {
  margin: 0;
  padding: 0;
}

.aboutmepage {
  padding-inline: 16px;
}

.page {
  margin-top: 76px;
}

.pageHome {
  margin-top: 60px;
  animation-name: fade;
  animation-duration: 1s;
}

.pagepadding {
  padding-inline: 16px;
  padding-bottom: 8px;
  width: 50%;
  margin: 0 auto;
}

.navbar {
  background-color: var(--main-bg);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 100;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 0;
  margin: 0;
  top: 0;
}

.servicepage {
  width: 50%;
  margin: 0 auto;
}

.navlisteleemnt {
  font-size: 1rem;
}

.navlistcenter {
  padding-top: 8px;
}

.navigation {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
  text-align: start;
  height: 100%;
}

nav {
  background-color: var(--main-color);
  height: 64px;
  width: 100%;
  z-index: 80;
}

.imprintvisible {
  display: none;
}

nav>ul>li>a {
  text-decoration: none;
  color: var(--main-text);
}

nav ul {
  float: right;
  margin-right: 20px;
}

nav ul li {
  display: inline-block;
  line-height: 46px;
  margin: 0 5px;
}

nav ul li a {
  color: var(--main-text);
  font-size: 16px;
  text-transform: uppercase;
  padding: 7px 13px;
}

.serviceother {
  margin-top: 16px;
  width: 100%;
  display: flex;
}

.serviceotherimg:hover {
  filter: grayscale(0%);
}

.serviceleft {
  border-radius: 16px 0px 0px 16px;
}

.serviceright {
  border-radius: 0px 16px 16px 0px;
}

.serviceotherimg {
  width: 100%;
  filter: grayscale(50%);
}

nav ul li a:hover,
a.active {
  text-decoration: underline;
  transition: 0.5s;
}

.checkbtn {
  font-size: 24px;
  color: white;
  float: right;
  line-height: 46px;
  margin-right: 20px;
  cursor: pointer;
  display: none;
}

#check {
  display: none;
}

.navimg {
  height: 100%;
  float: left;
}


.pagecontent {
  margin: 0;
}

.img {
  width: 100%;
  height: auto;
}

.homebuttons {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}

.buttonLink {
  padding: 18px 32px;
}

.listelement {
  text-align: left;
}

.listelementparent {
  margin-top: 1rem;
  margin-left: 16px;
}

.button {
  background-color: var(--main-color);
  /*padding: 12px 32px;*/
  border-radius: 32px;
  width: 33%;
  min-height: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  outline: var(--primary) solid 2px;
}

.socials {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 1rem;
  width: 25%;
}

.socialimages {
  height: 4.5rem;
  cursor: pointer;
  background-color: #343434;
  border-radius: 16px;
}

.socialimages:hover {
  background-color: var(--main-color);
  border-radius: 16px;
}

.serviceimg {
  max-width: 100%;
  border-radius: 16px;
  animation-name: dropin;
  animation-duration: 1s;
}

.timelineparent {
  width: 50%;
  margin: 0 auto;
}

.mebox {
  width: 50%;
  margin: 0 auto;
}

.contactcontainer {
  animation-name: fade;
  animation-duration: 1s;
}

.meimg {
  width: 100%;
  border-radius: 16px;
  animation-name: dropin;
  animation-duration: 1s;
}

.alignleft {
  text-align: left;
  width: 75%;
}

.line {
  width: 2px;
  background-color: white;
  margin: 0 auto;
}

.alignright {
  text-align: right;
  width: 75%;
}

.contactimg {
  height: 256px;
  border-radius: 16px;
  animation-name: dropin;
  animation-duration: 1s;
}

.philoimg>img {
  width: 100%;
}

.philoimg {
  width: 33.33%;
  cursor: pointer;
  filter: grayscale(50%);
}

.philoimg:hover {
  filter: grayscale(0%);
}

.philoimgcontainer {
  margin-top: 16px;
  width: 100%;
  display: flex;
  animation-name: fade;
  animation-duration: 1s;
}

.philoimgleft {
  border-radius: 16px 0 0 16px;
}

.philoimgright {
  border-radius: 0 16px 16px 0;
}

.rotateIcon {
  animation-name: rotateIcon;
  animation-duration: 1s;
}

@keyframes rotateIcon {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

.inputfield {
  width: 100%;
  padding: 12px 8px;
  margin: 8px 0;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  border: none;
  border-radius: 8px;
  background-color: var(--main-bg);
  color: white;
  font-size: 16px;
}

.inputfield:focus {
  outline: var(--main-color) solid 2px;
}

.textalign {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 8px;
}

.selectionButton {
  padding: 8px 0px;
  border-radius: 8px;
  height: 36px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  text-align: center;
  margin-inline: 4px;
  background-color: var(--main-bg);
}

.contactselectionbuttons {
  display: flex;
  justify-content: space-around;
  margin-top: 8px;
}

.homelayout {
  width: 50%;
  margin: 16px auto;
}

.footereugenium {
  color: white;
}

.footerdiv {
  height: 50px;
  align-items: end;
}

.footer {
  padding-block: 8px;
  font-size: 0.5rem;
  display: flex;
  width: 100%;
  background-color: #00000044;
  bottom: 0;
  position: fixed;
  backdrop-filter: blur(5px);
}

.dp {
  margin-left: 8px;
  margin-right: 8px;
}

.footereug {
  width: 100%;
  text-align: left;
  padding-left: 16px;
}

.footerright {
  display: inline;
  float: right;
  text-align: right;
  width: 100%;
  padding-right: 16px;
}

#imp,
#eug,
#dp {
  font-size: 1rem;
}

.textarea {
  width: 100%;
  padding: 12px 8px;
  margin: 8px 0;
  display: inline-block;
  min-height: 128px;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  border: none;
  border-radius: 8px;
  background-color: #343434;
  color: white;
  font-size: 16px;
}

.sendbutton {
  width: 25%;
  background-color: var(--main-color);
  padding: 12px 32px;
  border-radius: 32px;
  margin: 0 auto;
  cursor: pointer;
}

@media (max-width: 952px) {
  .serviceotherimg {
    filter: grayscale(0%);
  }

  .spacermobile {
    height: 16px;
  }

  .mobilepadding {
    padding-inline: 16px;
  }

  .endmargin {
    margin-bottom: 16px;
  }

  h3 {
    font-size: 1.25rem;
  }

  a {
    font-size: 1rem;
  }

  .navlisteleemnt {
    font-size: 16px;
  }

  .navlistcenter {
    padding-top: 0px;
  }

  .page {
    margin-top: 36px;
  }

  .pageHome {
    margin-top: 48px;
  }

  nav {
    height: 48px;
  }

  .button {
    width: 45%;
  }

  .buttonLink {
    padding: 18px 0px;
  }

  .margintopthings {
    padding-top: 32px;
  }

  .footerdiv {
    display: none;
  }

  .timelineparent {
    width: 100%;
    margin: 0 auto;
  }

  .meboxpadding {
    padding-inline: 16px;
    animation-name: fade;
    animation-duration: 1s;
  }

  .mebox {
    width: 100%;
    margin: 0 auto;
  }

  .philoimgcontainer {
    flex-direction: column;
  }

  .philoimg {
    width: 100%;
    cursor: pointer;
    margin: 0 auto;
  }

  .philoimgleft {
    border-radius: 16px;
  }

  .philoimgmiddle {
    width: 50%;
    border-radius: 16px;
    margin-top: 16px;
  }

  .philoimgright {
    border-radius: 16px;
    margin-top: 16px;
  }

  .servicepage {
    width: 100%;
    margin: 0 auto;
  }

  .serviceimg {
    max-width: 100%;
    border-radius: 0px;
  }

  .meimg {
    width: 100%;
    border-radius: 0;
  }

  .page {
    margin-top: 48px;
    padding-inline: 0;
  }

  .philoimg {
    width: 33.33%;
    cursor: pointer;
    filter: grayscale(0%);
  }

  .pagepadding {
    padding-inline: 16px;
    padding-bottom: 8px;
  }

  .line {
    width: 2px;
    background-color: white;
    margin: 0 auto;
  }

  nav ul li a {
    font-size: 16px;
  }

  .homelayout {
    box-sizing: border-box;
    padding-inline: 8px;
    width: 100%;
    gap: 8px;
  }

  .paddingimprint {
    padding-inline: 16px;
    width: 90%;
    margin: 0 auto;
  }
}

@media(max-width: 858px) {
  .spacermobile {
    height: 16px;
  }

  #margin {
    margin-bottom: 16px;
  }

  #packbtn {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  a {
    font-size: 1rem;
  }

  .navlisteleemnt {
    font-size: 20px;
  }

  .page {
    margin-top: 48px;
  }

  .pageHome {
    margin-top: 48px;
  }

  nav {
    height: 48px;
  }

  .timelineparent {
    width: 100%;
    margin: 0 auto;
  }

  .meboxpadding {
    padding-inline: 16px;
  }

  .mebox {
    width: 100%;
    margin: 0 auto;
  }

  .philoimg {
    width: 90%;
    cursor: pointer;
    margin: 0 auto;
  }

  .pagepadding {
    padding-inline: 16px;
    padding-bottom: 8px;
    width: auto;
  }

  .paddingfifty {
    padding-inline: 16px;
    width: 90%;
    margin: 0 auto;
  }

  .paddingimprint {
    padding-inline: 16px;
    width: 90%;
    margin: 0 auto;
  }

  .button {
    background-color: var(--main-color);
    /*padding: 12px 32px;*/
    border-radius: 32px;
    width: 75%;
    min-height: 36px;
    cursor: pointer;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }

  .line {
    width: 2px;
    background-color: white;
    margin: 0 auto;
  }

  footer {
    display: none;
  }

  .line {
    margin: 0;
  }

  .imprintvisible {
    display: unset;
  }

  .homelayout {
    width: 100%;
  }

  .textarea {
    width: 100%;
  }

  .inputfield {
    width: 100%;
  }

  .checkbtn {
    display: block;
  }

  ul {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: var(--main-color-transparent);
    backdrop-filter: blur(5px);
    text-align: center;
    top: 48px;
    right: -100%;
    transition: all 0.4s;
  }

  .spacer {
    height: 96px;
  }

  .smaller {
    font-size: 16px;
  }

  nav ul li {
    display: block;
    text-align: center;
  }

  nav ul li a {
    font-size: 20px;
  }

  #check:checked~ul {
    right: 0;
  }
}

/* Super wide-screen*/
@media(min-aspect-ratio: 1.8/1) {
  .img {
    width: 50%;
    height: auto;
    border-radius: 16px;
    margin-top: 16px;
  }
}

.container {
  padding: 16px;
  background-color: #343434;
  border-radius: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  margin: 16px;
}

.smallball {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: var(--main-color);
  border: 4px solid var(--main-color-dark);
  position: absolute;
  transform: translateX(-44px);
}

.timeline {
  display: flex;
  margin: 0 auto;
}

.selected {
  outline: 2px solid var(--main-color);
  text-decoration: underline;
  background-color: var(--selected);
}


@keyframes dropin {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}